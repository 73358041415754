<template>
    <div>
            <orders :files="1"></orders>
    </div>
</template>

<script>
import Orders from './Orders.vue'
export default {
    name: '',
    components:{
        Orders
    },

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>